import service from './network';

export function queryEquipmentList(data) {
    return service.request({
        url: '/equipment/query',
        method: 'post',
        data
    })
}

export function queryEquipmentStatus(data) {
    return service.request({
        url: '/equipment/status/list',
        method: 'get',
        data
    })
}

export function uploadEquipmentApi(data) {
    return service.request({
        url: '/equipment/upload',
        method: 'post',
        data
    })
}

export function exportEquipmentApi(data) {
    return service.request({
        url: '/equipment/download',
        method: 'get',
        data,
        responseType: 'blob'
    })
}

export function bindEquipmentApi(data) {
    return service.request({
        url: '/equipment/bind/collect/point',
        method: 'post',
        data
    })
}

/**
 *查询设备类型列表
 */
export function queryEquipmentTypeApi(data) {
    return service.request({
        url: '/equipment/type/query',
        method: 'post',
        data
    })
}

/**
 *下载设备类别表
 */
export function exportEquipmentTypeApi(data) {
    return service.request({
        url: '/equipment/type/download',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}

/**
 *删除设备类别
 */
export function deleteEquipmentTypeApi(data) {
    return service.request({
        url: '/equipment/type/delete',
        method: 'delete',
        data,
    })
}

/**
 *根据厂商编号查找类型
 */
export function queryEquipmentTypeByManuApi(data) {
    return service.request({
        url: '/equipment/type/list',
        method: 'get',
        params: data
    })
}

/**
 *删除设备
 */
export function deleteEquipmentApi(data) {
    return service.request({
        url: '/equipment/delete',
        method: 'delete',
        data
    })
}

export function updateEquipmentWhiteApi(data) {
    return service.request({
        url: '/device/white/list/update',
        method: 'put',
        data
    })
}

/**
 *修改设备状态
 */
export function updateEquipmentStatusApi(data) {
    return service.request({
        url: '/equipment/update/status',
        method: 'put',
        data
    })
}

export function updateEquipmentInfoApi(data) {
    return service.request({
        url: '/equipment/update/info',
        method: 'put',
        data
    })
}

export function refreshEquipmentInfoApi(data) {
    return service.request({
        url: '/equipment/update/refresh/' + data,
        method: 'put',
    })
}

export function queryEquipmentInfoApi(data) {
    return service.request({
        url: '/equipment/query/info',
        method: 'get',
        params: data
    })
}

export function queryEquipmentTypeListApi(data) {
    return service.request({
        url: '/equipment/type/array',
        method: 'get',
        params: data
    })
}
