<template>
    <div>
        <a-modal title="绑定设备" @ok="handleOk" v-model="bindVisible" width="900px">
            <a-input placeholder="输入归集点关键词" v-model="r_params.name" @change="queryByName" style="width: 150px;margin-bottom: 10px;"/>
            <a-table :columns="columns" :data-source="residentials" class="components-table-demo-nested" :scroll="{ y: 440 }" :pagination="pagination">
                <span slot="action" slot-scope="text, record">
                    <a @click="viewCollections(record)">查看</a>
                </span>
            </a-table>
        </a-modal>
        <a-modal title="归集点信息" @ok="handleOk2" v-model="collectVisible" width="900px">
            <a-table :columns="collectColumns" :data-source="collections" class="components-table-demo-nested" :scroll="{ y: 440 }">
                <span slot="isBind" slot-scope="text, record">
                   <p v-if="record.isBind"><a href="#" @click="viewBindEquipment(record)">已有设备</a></p>
                   <p v-else>无</p>
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-popconfirm
                        title="确定将设备绑定到该采集点?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="bindToCollectPoint(record)"
                        @cancel="cancel"
                    >
                        <a >绑定到该采集点</a>
                    </a-popconfirm>
                </span>
            </a-table>
        </a-modal>
        <a-modal title="已有设备" @ok="handleOk3" v-model="hasEquipmentVisible" width="900px">
            <a-table :columns="equip_columns" :data-source="equipments" class="components-table-demo-nested" :scroll="{ y: 440 }">
                <span slot="action" slot-scope="text, record">
                    <a-popconfirm
                        title="确定接触绑定?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="unBind(record)"
                        @cancel="cancel"
                    >
                        <a >解除绑定</a>
                    </a-popconfirm>
                </span>
            </a-table>
        </a-modal>
    </div>
 
</template>

<script>
import { queryCollectByResidentialCode } from '@/api/collection'
import { queryResidentialCommunityList} from '@/api/residential'
import {bindEquipmentApi} from '@/api/equipment'

export default {
    props: {
        device: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            pagination: {
                pageSize: 15,
                current: 1,
                total: 0,
                onChange: (current) => this.changePage(current)
            },
            hasEquipmentVisible: false,
            collectVisible: false,
            bindVisible: false,
            columns: [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    ellipsis: true,
                },
                {
                    title: '归集点名称',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: '所属区域',
                    dataIndex: 'areaName',
                    key: 'areaName'
                },
                {
                    title: '所属街道',
                    dataIndex: 'streetName',
                    key: 'streetName'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    scopedSlots: {customRender: 'action'}
                }
            ],
            collectColumns: [
                {
                    title: '采集点编号',
                    dataIndex: 'collectPointCode',
                    key: 'collectPointCode',
                    ellipsis: true,
                },
                {
                    title: '采集点名称',
                    dataIndex: 'collectPointName',
                    key: 'collectPointName'
                },
                {
                    title: '归集点编号',
                    dataIndex: 'residentialCode',
                    key: 'residentialCode'
                },
                {
                    title: '当前有无设备',
                    dataIndex: 'isBind',
                    key: 'isBind',
                    scopedSlots: {customRender: 'isBind'}
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    scopedSlots: {customRender: 'action'}
                }
            ],
            residentials: [],
            r_params: {
                page: 1,
                limit: 15,
                name: ''
            },
            collections: [],
            equip_columns: [
                {
                    title: '设备编号',
                    dataIndex: 'equipmentCode',
                    key: 'equipmentCode'
                },
                {
                    title: '设备名称',
                    dataIndex: 'equipmentName',
                    key: 'equipmentName'
                },
                {
                    title: '设备类型',
                    dataIndex: 'equipmentType',
                    key: 'equipmentType'
                },
                {
                    title: '采集点编号',
                    dataIndex: 'pointCode',
                    key: 'pointCode'
                },
                {
                    title: '归集点编号',
                    dataIndex: 'residentialCode',
                    key: 'residentialCode'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    scopedSlots: {customRender: 'action'}
                }
            ],
            equipments: []
        }
    },
    methods: {
        changePage(current) {
            this.r_params.page = current
            this.queryResidentialCommunity()
        },
        queryByName() {
            this.r_params.page = 1
            this.queryResidentialCommunity()
        },
        viewBindEquipment(record) {
            this.hasEquipmentVisible = true
            this.equipments = record.equipments
            this.equipments.forEach(item => {
                item.pointCode = record.collectPointCode
                item.residentialCode = record.residentialCode
            })
        },
        unBind(record) {
            console.log(record)
             bindEquipmentApi({equipmentCode: record.equipmentCode, pointCode: record.pointCode, isBind: false}).then(res => {
                this.$message.success('已解除!')
                queryCollectByResidentialCode(record.residentialCode).then(res => {
                    this.collections = res.result
                    this.equipments = this.collections.equipments
                })
                this.$emit('queryEquipmentInfo', this.device.equipmentCode)
            }).catch(() => {
                this.$$message.error('解除失败!')
            })
        },
        bindToCollectPoint(record) {
            bindEquipmentApi({equipmentCode: this.device.equipmentCode, pointCode: record.collectPointCode, isBind: true}).then(res => {
                this.$message.success('绑定成功!')
                this.bindVisible = false
                this.collectVisible = false
                this.$emit('queryEquipmentInfo', this.device.equipmentCode)
            }).catch(() => {
                this.$$message.error('绑定失败!')
            })
        },
        viewCollections(record) {
            this.collectVisible = true
            queryCollectByResidentialCode(record.systemCode).then(res => {
                this.collections = res.result
            })
        }, 
        handleOk2() {
            this.collectVisible = false
        },
        handleOk() {
            this.bindVisible = false
        },
        handleOk3() {
            this.hasEquipmentVisible = false
        },
        showBindModal() {
            this.bindVisible = true
            this.queryResidentialCommunity()
        },
        queryResidentialCommunity() {
            queryResidentialCommunityList({...this.r_params}).then(res => {
                this.residentials  = res.result.content
                this.residentials.forEach((item, index) => {
                    item.areaName = item.address.areaName
                    item.streetName = item.address.streetName
                    item.name = item.information.name
                })
                this.pagination.current = res.result.pageNo + 1
                this.pagination.total = res.result.totalElements
            })
        }
    }
}
</script>