import service from './network';

export function queryResidentialTypeList(data) {
    return service.request({
        url: '/residential/type/query',
        method: 'post',
        data
    })
}

/**
 *
 * @param {*} data
 * 归集点-小区信息
 */
export function queryResidentialCommunityList(data) {
    return service.request({
        url: '/residential/query',
        method: 'post',
        data
    })
}

/**
 *
 * @param {*} data
 * 归集点-生活垃圾采集
 */
export function updateResidentialCommunity(data) {
    return service.request({
        url: '/residential/update',
        method: 'put',
        data
    })
}

/**
 *
 * @param {删除归集点} data
 */
export function deleteResidentialCommunity(data) {
    return service.request({
        url: '/residential/delete',
        method: 'delete',
        data
    })
}

/**
 *导出归集点
 * @param {*} data
 */
export function exportResidentialCommunity(data) {
    return service.request({
        url: '/residential/download',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}

/**
 *
 * @param {导入归集点} data
 */
export function uploadResidentialCommunity(data) {
    return service.request({
        url: '/residential/upload',
        method: 'post',
        data
    })
}

export function updateResidential(data) {
    return service.request({
        url: '/residential/type/update',
        method: 'put',
        data
    })
}

export function exportResidentialApi(data) {
    return service.request({
        url: '/residential/type/download',
        method: 'get',
        data,
        responseType: 'blob'
    })
}

export function deleteResidential(data) {
    return service.request({
        url: '/residential/type/delete',
        method: 'delete',
        data
    })
}

export function uploadResidential(data) {
    return service.request({
        url: '/residential/type/upload',
        method: 'post',
        data
    })
}

export function updateResidentialCodeApi(data) {
    return service.request({
        url: '/residential/update/code',
        method: 'put',
        data
    })
}

export function refreshResidentialDataApi(data) {
    return service.request({
        url: '/residential/update/refresh/' + data,
        method: 'put',
        data
    })
}

export function refreshDockingApi(data) {
    return service.request({
        url: '/residential/fresh/all/bind/info/' + data + '/all',
        method: 'put',
        data
    })
}

export function addHouseApi(data) {
    return service.request({
        url: '/residential/add/house/' + data,
        method: 'put',
        data
    })
}

export function addSubcribeApi(data) {
    return service.request({
        url: '/residential/subscribe/add',
        method: 'post',
        data
    })
}

export function pushResidentialDataApi(data) {
    return service.request({
        url: '/residential/push',
        method: 'put',
        data
    })
}

export function queryResidentialTypeApi(data) {
    return service.request({
        url: '/residential/type/list',
        method: 'get',
        data
    })
}

export function updateInfrastructureCodeApi(data) {
    return service.request({
        url: '/generate/infrastructure/update/code',
        method: 'post',
        data
    })
}
