<template>
<a-modal title="编辑" @ok="handleOk" v-model="visibleEdit">
  <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" >
    <a-form-item label="设备名称">
      <a-input v-model="device.name"/>
    </a-form-item>
    <a-form-item label="设备类型">
      <a-select
        placeholder="选择设备类型"
        @change="handleTypeSelectChange"
        :default-value="device.equipmentTypeCode"
      >
        <a-select-option :value="item.typeConstant" v-for="(item, index) in types" :key="index">
          {{item.typeName}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="设备状态">
      <a-select
        placeholder="选择设备状态"
        @change="handleStatusSelectChange"
        :default-value="device.equipmentStatus"
      >
        <a-select-option :value="item.code" v-for="(item, index) in statusList" :key="index">
          {{item.name}}
        </a-select-option>
      </a-select>
    </a-form-item>
      <a-form-item label="设备描述">
      <a-input v-model="device.describe"/>
    </a-form-item>
  </a-form>
  </a-modal>
</template>

<script>
import { updateEquipmentInfoApi, queryEquipmentTypeListApi, queryEquipmentStatus } from '@/api/equipment'
export default {
    props: {
        device: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            visibleEdit: false,
            formLayout: 'horizontal',
            types: [],
            statusList: []
        };
    },
    methods: {
        queryEquipmentTypeList() {
            queryEquipmentTypeListApi().then(res => {
                this.types = res.result
            })
        },
        handleTypeSelectChange(e) {
            this.device.equipmentTypeCode = e
        },
        handleStatusSelectChange(e) {
            this.device.equipmentStatus = e
        },
        queryStatus() {
            queryEquipmentStatus().then(res => {
                this.statusList = res.result
            })
        },
        showEditModal() {
        this.visibleEdit = true
        this.queryEquipmentTypeList()
        this.queryStatus()
        },
        handleOk() {
            updateEquipmentInfoApi({...this.device}).then(res => {
                this.$message.success('修改成功')
                this.visibleEdit = false
                this.$emit('queryEquipmentInfo', this.device.equipmentCode)
            }).catch(() => {
                this.$message.error('修改失败')
            })
        },
        handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
            if (!err) {
            console.log('Received values of form: ', values);
            }
        });
        },
        handleSelectChange(value) {
        this.form.setFieldsValue({
            note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
        });
        },
    },
};
</script>