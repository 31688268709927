// import SockJS from 'sockjs-client';
// import Stomp from 'stompjs';
// import { getToken} from '@/utils/auth'
export default {
    data() {
     return {
        //  socket: '',
        //  stompClient: ''
     }
    },
    created() {
        // let _this = this
        // this.socket = new SockJS("http://192.168.0.159:8100/ping")
        // this.stompClient = Stomp.over(this.socket);
        // window.onload = function () {
        //     _this.connect();
        // }
    },
    mounted() {},
    methods: {
        copyQuick(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            console.log(oInput.value)
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success('复制成功');
            oInput.remove()
        },
    }
   }