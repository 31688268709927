<template>
    <div>
        <a-drawer
            title="设备详情"
            placement="right"
            :closable="false"
            :visible="visible"
            width="1000"
            @close="onClose"
        >
            <!-- <a-button type="primary" @click="goBack"> 返回</a-button> -->
        <a-tabs :default-active-key="1" @tabClick="changeTab">
                <a-tab-pane :key="1" tab="设备详情">
                    <a-card size="small" title="基本信息" style="width: 100%;">
                        <a slot="extra" href="#" @click="showEditModal">编辑</a>
                        <div class="device-detail-p">
                            <p>设备id： {{device.id}}</p>
                            <p>设备编号： {{device.equipmentCode}}</p>
                            <p>设备名称： {{device.name}}</p>
                            <p>设备类型： {{device.equipmentType}}</p>
                            <p>出厂编号： {{device.deliveryCode}}</p>
                            <p>出厂时间： {{device.deliveryTime | formatDateTime}}</p>
                            <p>设备状态： {{device.equipmentStatus}}</p>
                            <p>所属区域： {{device.areaName}}</p>
                            <p>所属街道： {{device.streetName}}</p>
                        </div>
                    </a-card>
                    <a-card size="small" title="设备绑定" style="width: 100%;margin-top:2%;">
                        <a slot="extra"  v-if="!device.isBind" @click="toBind">绑定到采集点</a>
                        <a slot="extra"  v-else>已绑定</a>
                        <a slot="extra"  v-if="device.isBind" style="margin-left:20px;color:red;">
                            <a-popconfirm
                                slot="extra"
                                v-if="device.isBind"
                                title="确定接触绑定?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="unBind"
                                >
                                解除绑定
                            </a-popconfirm>
                            </a>
                        <div class="device-detail-p">
                            <p v-if="!device.isBind">设备未绑定</p>
                            <a-card :bordered="false"  v-else>
                            <p>采集点：{{device.pointName}}</p>
                            <p>采集点编号： {{device.pointCode}}</p>
                            <p>归集点：{{device.residentialName}}</p>
                            <p>归集点编号：{{device.residentialCode}}</p>
                            </a-card>
                        </div>
                    </a-card>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="实时数据" >
                    <deviceAcutal ref="deviceAcutal"></deviceAcutal>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="异常拦截">
                    <restrictData ref="restrictData"/>
                </a-tab-pane>
                <a-tab-pane :key="4" tab="模拟推送任务">
                    <virtualMession ref="virtualMession"></virtualMession>
                </a-tab-pane>
                </a-tabs>
                <edit-modal ref="editModal" :device="device"></edit-modal>
                <bind-modal ref="bindModal" :device="device" @queryEquipmentInfo="queryEquipmentInfo"/>
        </a-drawer>
    </div>
</template>

<script>
import virtualMession from '@/components/residentialMgt/component/virtualMession'
import restrictData from '@/components/route/restrictData'
import deviceAcutal from '@/components/equipmentMgt/component/deviceAcutal'
import bindModal from '@/components/equipmentMgt/component/bindModal'
import editModal from '@/components/equipmentMgt/component/editModal'
import {bindEquipmentApi, queryEquipmentStatus, queryEquipmentInfoApi} from '@/api/equipment'
import { queryCollectByResidentialCode } from '@/api/collection'
import moment from 'moment'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        
        {
            title: '归集点',
            dataIndex: 'centerName',
            key: 'centerName',
            width: 0,
            ellipsis: true,
        },
        {
            title: '清洁屋',
            dataIndex: 'houseName',
            key: 'houseName',
            width: 0,
             ellipsis: true,
        },
        {
            title: '重量(kg)',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: '垃圾类别',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: '设备编号',
            dataIndex: 'deviceCode',
            key: 'deviceCode',
        },
        {
            title: '垃圾桶',
            dataIndex: 'trashCode',
            key: 'trashCode',
        },
        {
            title: '采集时间',
            dataIndex: 'collectTime',
            key: 'collectTime',
            width: 200,
            scopedSlots: { customRender: 'collectTime' },
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            scopedSlots: { customRender: 'action' },
          }
]
export default {
  components: { editModal, bindModal , deviceAcutal, restrictData, virtualMession},
  data() {
    return {
        device: {},
        visible: false,
        rowSelection,
        selectedRowKeys: [],
        columns,
        loading: false,
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => {
            return '共' + total + '条'
            },
            pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
            onChange: (current, size) => this.changePage(current, size),
            onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
        },
        tableData: []
        }
    },
    filters: {
      formatDateTime(value) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    methods: {
        changeTab(e) {
            if(e == 2) {
                setTimeout(() => {
                    this.$refs.deviceAcutal.queryComprehensiveData(this.params.equipmentCode)
                }, 200);
            } else if(e == 4) {
                setTimeout(() => {
                    this.$refs.virtualMession.queryData(this.params.equipmentCode)
                }, 200)
            }
        },
        showDrawer(record) {
            this.visible = true;
            let tmp = record
            this.params = {...tmp}
            this.queryEquipmentInfo(this.params.equipmentCode)
            localStorage.setItem('fullPath', this.$route.fullPath)
        },
        onClose() {
            this.visible = false;
            this.$emit('query')
        },
        unBind() {
            bindEquipmentApi({equipmentCode: this.device.equipmentCode, pointCode: this.device.pointCode, isBind: false}).then(res => {
                this.$message.success('解除绑定成功')
                this.queryEquipmentInfo(this.device.equipmentCode)
            }).catch(()=> {
                this.$message.error('解除失败')
            })
        },
        showEditModal() {
            this.$refs.editModal.showEditModal()
        },
        toBind() {
            this.$refs.bindModal.showBindModal()
        },
        queryEquipmentInfo(code) {
            queryEquipmentInfoApi({equipmentCode: code}).then(res => {
                this.device = res.result
            })
        },
        queryCollectPoint(residential){
            console.log(residential)
            queryCollectByResidentialCode(residential.systemCode).then(res => {
                this.houseList = res.result
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        queryAreaList() {
            this.$getRegionNodeChild("320500000000").then(res => {
                this.areaData = res
            })
        },
        handleStreetChange(street) {
            this.formQuery.streetCode = street
        },
        handleAreaChange(area) {
            this.formQuery.areaCode = area
            this.formQuery.streetCode = ''
            this.$getRegionNodeChild(area).then(res => {
                this.streetData = res
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        onChangeSelect(e) {
            this.detailInfo.equipmentStatus = e
        },
        onChangeInput(e, key) {
            this.detailInfo[key] = e.target.value
        },
        queryStatus() {
            queryEquipmentStatus().then(res => {
                this.statusList = res.result
            })
        },
        toEdit() {
            this.edit = true
        },
        save() {
            this.edit = false
        },
        cancel() {
            this.edit = false
        }
    }
}
</script>

<style lang="scss">
.device-detail-p{
    p{
        font-weight: bolder;
        font-size: 16px;
    }
}
</style>