import service from './network';

export function queryCollectionList(data) {
    return service.request({
        url: '/collection/point/query',
        method: 'post',
        data
    })
}

export function exportCollectionApi(data) {
    return service.request({
        url: '/collection/point/download',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}

export function deleteCollectionApi(data) {
    return service.request({
        url: '/collection/point/delete',
        method: 'delete',
        data
    })
}

export function updateCollectionApi(data) {
    return service.request({
        url: '/collection/point/update',
        method: 'put',
        data
    })
}

export function updateCollectionHouseCodeApi(data) {
    return service.request({
        url: '/collection/point/update/sort',
        method: 'put',
        data
    })
}

export function queryCollectByResidentialCode(query) {
    return service.request({
        url: '/collection/point/query/' + query,
        method: 'get'
    })
}


export function updateCollectionCodeApi(data) {
    return service.request({
        url: '/collection/point/update/code',
        method: 'put',
        data
    })
}

export function refreshCollectionApi(data) {
    return service.request({
        url: '/collection/point/update/refresh/' + data,
        method: 'put',
        data
    })
}
