<template>
    <div class="virtual-mession">
        <a-button type="link" @click="Add"  size="small"> <a-icon type="plus" />新增任务 </a-button>
        <a-popconfirm
            title="确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirmDelete('multiple')"
        >
           <a-button type="danger" ghost  size="small"> 删除任务 </a-button>
        </a-popconfirm>
        <a-button type="primary" @click="queryAll"  size="small" ghost style="margin-left: 10px;"> 查看全部 </a-button>
        <a-button type="primary" @click="queryCurrent"  size="small" ghost style="margin-left: 10px;"> 只看当前设备 </a-button>
        <a-table style="margin-top: 15px;" size="small" :columns="columns" :data-source="data" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"  :loading="loading" :pagination="pagination">
            <template slot-scope="text, record" slot="planCode">
                {{record.planCode}}
            </template>
            <template slot-scope="text, record" slot="planActivate">
                <a-popconfirm
                    :title="`是否${record.planActivate ? '取消激活' : '激活'} ? `"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmActive(record)"
                >
                <a-button :type="record.planActivate ? 'primary' : 'danger'" ghost  size="small"> {{record.planActivate ? '已激活' : '未激活'}} </a-button>                
                </a-popconfirm>
            </template>
            <template slot-scope="text, record" slot="deviceCode">
                <a-input v-model="record.deviceCode" v-if="record.editable"/>
                <span v-else>{{record.deviceCode}}</span>
            </template>
            <template slot-scope="text, record" slot="startTime">
                <a-date-picker  v-model="record.startTime" :format="dateFormat" v-if="record.editable"/>
                <span v-else>{{record.startTime | formatTime}}</span>
            </template>
            <template slot-scope="text, record" slot="endTime">
                <a-date-picker  v-model="record.endTime"  :format="dateFormat" v-if="record.editable"/>
                <span v-else>{{record.endTime | formatTime}}</span>
            </template>
            <template slot-scope="text, record" slot="action">
                <a-button type="primary" @click="edit(record)" v-if="!record.editable" size="small"> 编辑 </a-button>
                <a-button type="primary" @click="save(record)" v-if="record.editable"  size="small"> 保存 </a-button>
                <a-button type="default" @click="cancel(record)" v-if="record.editable"  size="small"> 取消 </a-button>
                <a-button type="info" @click="showRecord(record)" size="small" v-if="record.planCode" style="margin-left: 10px;"> 记录 </a-button>
                <record-drawer ref="recordDrawer"/>
            </template>
        </a-table>
    </div>
</template>
<script>
import moment from 'moment'
import { addPlanApi, queryMessionDataApi, updatePlanApi, deleteMessionDataApi, updateActiveApi } from '@/api/plan'
import RecordDrawer from '../../equipmentMgt/component/recordDrawer.vue';
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
export default {
    components: {RecordDrawer},
    data() {
        return{
            deviceCode: '',
            params: {
                page: 1,
                limit: 10
            },
            dateFormat: 'YYYY/MM/DD',
            newData: {
                planCode: '',
                deviceCode: '',
                startTime: '',
                endTime: '',
                editable: true,
            },
            selectedRowKeys: [],
            loading: false,
            data: [],
            rowSelection,
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                onChange: (current, size) => this.changePage(current, size)
            },
            columns: [
                {
                    dataIndex: 'id',
                    title: 'Id',
                    key: 'id',
                    ellipsis: true,
                    width: 0
                },
                {
                    dataIndex: 'centerName',
                    title: '归集点',
                    key: 'centerName',
                    ellipsis: true,
                },
                {
                    dataIndex: 'planCode',
                    title: '任务编号',
                    key: 'planCode',
                    ellipsis: true,
                    width: 0,
                    scopedSlots: { customRender: 'planCode' },
                },
                {
                    dataIndex: 'planActivate',
                    title: '激活状态',
                    key: 'planActivate',
                    scopedSlots: { customRender: 'planActivate' },
                    align: 'center'
                },
                {
                    dataIndex: 'deviceCode',
                    title: '设备编号',
                    key: 'deviceCode',
                    scopedSlots: { customRender: 'deviceCode' },
                },
                {
                    dataIndex: 'startTime',
                    title: '开始时间',
                    key: 'startTime',
                    scopedSlots: { customRender: 'startTime' },
                },
                {
                    dataIndex: 'endTime',
                    title: '结束时间',
                    key: 'endTime',
                    scopedSlots: { customRender: 'endTime' },
                },
                {
                    dataIndex: 'action',
                    title: '操作',
                    scopedSlots: { customRender: 'action' },
                }
            ]
        }
    },
    filters: {
        formatTime(e) {
            return moment(e).format('YYYY-MM-DD')
        }
    },
    methods: {
        showRecord(record){
            setTimeout(() => {
                this.$refs.recordDrawer.showRecordDrawer(record.deviceCode)
            }, 200)
        },
        confirmActive(record){
            updateActiveApi(record.planCode).then(res => {
                if(res.code == '0') {
                    this.$message.success('操作成功')
                    this.queryMessionData()
                }
            })
        },
        queryData(code) {
            this.deviceCode = code
            this.queryMessionData()
        },
        queryAll() {
            this.pagination.current = 1
            this.params.deviceCode = ''
            this.queryMessionData()
        },
        queryCurrent() {
            this.pagination.current = 1
            this.params.deviceCode = this.deviceCode
            this.queryMessionData()
        },
        queryMessionData() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryMessionDataApi({...this.params}).then(res => {
                this.loading = false
                this.data = res.result.content
                this.pagination.current = res.result.number + 1
                this.pagination.total = res.result.totalElements
                this.newData.planCode = ''
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryMessionData()
        },
        Add() {
            this.newData.planCode = ''
            this.newData.deviceCode = this.params.deviceCode
            this.data = [this.newData, ...this.data]
        },
        save(record) {
            const newData = [...this.data];
            const target = newData.filter(item => record.key === item.key)[0];
            if(record.planCode) {
                updatePlanApi(record).then(res => {
                    this.queryMessionData()
                })
            } else {
                addPlanApi(record).then(res => {
                    this.queryMessionData()
                })
            }
        },
        edit(record) {
            const newData = [...this.data];
            if (record) {
                record.editable = true;
                this.data = newData;
            }
        },
        cancel() {
           this.newData.planCode = ''
           this.queryMessionData()
        },
        confirmDelete(type, data) {
          let ids = []
          if(type == 'multiple') {
              this.selectedRowKeys.forEach(item => {
                  ids.push(this.data[item].id)
              })
          } else {
              ids.push(data.id)
          }
          this.deleteByIds(ids)
        },
        deleteByIds(ids) {
            deleteMessionDataApi(ids).then(res => {
                this.$message.success('删除成功')
                this.pagination.current = 1
                this.pagination.total = 0
                this.queryMessionData()
                this.selectedRowKeys = []
            })
            .catch(() => {
                this.$message.error('删除失败')
            })
        }
    }
}
</script>

<style lang="scss">
.virtual-mession{
    .ant-table-thead{
        background: #f9fbfd;
    }
}
</style>