<template>
    <div>
        <a-drawer
            width="1080"
            title="数据记录"
            :visible="recordVisible"
            @close="onClose"
        >
        <deviceAcutal ref="deviceAcutal"></deviceAcutal>
      </a-drawer>
    </div>
</template>

<script>
import deviceAcutal from './deviceAcutal'
export default {
    components: { deviceAcutal },
    data() {
        return{
            recordVisible: false,
        }
    },
    methods: {
        showRecordDrawer(code) {
            this.recordVisible = true
            setTimeout(() => {
                this.$refs.deviceAcutal.queryComprehensiveData(code)
            }, 200)
        },
        onClose() {
            this.recordVisible = false
        }
    }
}
</script>
