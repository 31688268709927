import service from './network';

export function queryMessionDataApi(data){
    return service.request({
        url: '/management/device/plan/query',
        method: 'post',
        data
    })
}

export function deleteMessionDataApi(data){
    return service.request({
        url: '/management/device/plan/delete',
        method: 'delete',
        data
    })
}

/**
 *新增模拟任务
 * @param {*} data 
 * @returns 
 */
 export function addPlanApi(data){
    return service.request({
        url: '/management/device/plan/add',
        method: 'post',
        data
    })
}

export function updatePlanApi(data){
    return service.request({
        url: '/management/device/plan/update',
        method: 'put',
        data
    })
}

export function updateActiveApi(data){
    return service.request({
        url: '/management/device/plan/active/' + data,
        method: 'put',
        data
    })
}


