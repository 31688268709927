<template>
    <div id="components-form-demo-advanced-search">
        <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
                <a-col
                        v-for="(i, idx) in formItems"
                        :key="`${idx} -1`"
                        :span="8"
                        :style="{ display: idx < count ? 'block' : 'none' }"
                >
                    <a-form-item :label="i.label">
                        <a-input
                                v-if="i.type === 'input'"
                                :value="params[i.value]"
                                allowClear
                                placeholder="请输入"
                                @change="change($event, i.value)"
                                @pressEnter="onSearch"
                        />
                        <a-select v-if="i.type === 'select'" allowClear @change="handleChangeSelect">
                            <a-select-option v-for="(item, index) in statusList" :key="`${index}-1`" :value="item.code">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                        <a-range-picker v-if="i.type === 'date'" @change="changDate">
                            <template slot="renderExtraFooter">
                                extra footer
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="24" :style="{ textAlign: 'right' }">
                    <a-button :style="{ marginLeft: '8px' }" @click="showKsReport">
                        昆山设备报表
                    </a-button>
                    <a-button :style="{ marginLeft: '8px' }" html-type="submit" type="primary" @click="onSearch">
                        查询
                    </a-button>
                    <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                        重置
                    </a-button>
                    <a-button :style="{ marginLeft: '8px' }" @click="uploadEquipment">
                        导入设备数据
                    </a-button>
                    <a-button :style="{ marginLeft: '8px' }" type="danger" @click="deleteEquipment">
                        删除设备
                    </a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        展开
                        <a-icon :type="expand ? 'up' : 'down'"/>
                    </a>
                </a-col>
            </a-row>
        </a-form>
        <a-row justify="space-between" style="margin-top: 20px;" type="flex">
            <a-col :span="3">
                <download-excel :data="jsonData" :fields="jsonFields" name="设备基本信息.xls" type="xls"
                                worksheet="设备基本信息">
                    <a-button> 下载设备基本信息</a-button>
                </download-excel>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :pagination="pagination"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                 :scroll="{  x: 1500 , y: 500}"
                 bordered size="small"
                 style="margin-top: 20px;">
            <span slot="equipmentCode" slot-scope="text, record"
                  @click="copyQuick(record.equipmentCode)">【{{ record.equipmentCode }}】</span>
            <span slot="addWhiteList" slot-scope="text, record">
            <a-popconfirm
                    :title="record.addWhiteList ? '移出白名单?' : '加入白名单?'"
                    cancel-text="取消"
                    ok-text="确定"
                    @confirm="confirmAddWhiteList(record)"
            >
                <a>{{ record.addWhiteList ? '移出白名单' : '加入白名单' }}</a>
            </a-popconfirm>
        </span>
            <span slot="action" slot-scope="text, record">
            <a style="margin-right: 10px;" @click="showEditStatus(record)">修改设备状态</a>
            <a style="margin-right: 10px;" @click="viewDetail(record)">设备详情</a>
            <a style="margin-right: 10px;" @click="refresh(record)">刷新基础信息</a>
        </span>
        </a-table>
        <upload-comm ref="uploadComm" :action="action" :title="title" @querydata="queryEquipment"/>
        <a-modal v-model="visibleEquipmentStatus" cancel-text="取消" ok-text="确认" title="修改设备状态"
                 @cancel="hideModal" @ok="changeEquipmentStatus">
            <label>修改设备状态: </label>
            <a-select style="width: 200px" @change="selectEquipmentStatus">
                <a-select-option v-for="(item, index) in statusList" :key="`${index}-1`" :value="item.value">
                    {{ item.text }}
                </a-select-option>
            </a-select>
        </a-modal>
        <equipment-detail-drawer ref="equipmentDetailDrawer" @query="queryEquipment"/>
    </div>
</template>
<script>
import equipmentDetailDrawer from './component/equipmentDetailDrawer.vue'
import EquipmentDetailDrawer from './component/equipmentDetailDrawer.vue'
import moment from 'moment'
import mixin from '@/utils/mixin'
import uploadComm from '../common/uploadComm/index.vue'
import Obj from './formItems'
import {
    deleteEquipmentApi,
    exportEquipmentApi,
    queryEquipmentList,
    queryEquipmentStatus,
    refreshEquipmentInfoApi,
    updateEquipmentStatusApi,
    updateEquipmentWhiteApi
} from '@/api/equipment'

export default {
    components: {equipmentDetailDrawer},
    mixins: [mixin],
    data() {
        return {
            expand: false,
            visibleEquipmentStatus: false,
            tableDataBak: [],
            title: '上传设备数据',
            action: 'https://www.zhongshihudong.com/garbage-collect-server/equipment/upload',
            // action: 'http://192.168.0.155:8081/equipment/upload',

            params: {
                equipmentCode: '',
                equipmentName: '',
                equipmentStatus: '',
                deliveryCode: '',
                manufacturerName: '',
                deliveryStartTime: '',
                deliveryEndTime: ''
            },
            statusList: [],
            selectedRowKeys: [],
            columns: Obj.columns,
            tableData: [],
            loading: false,
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                    return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange: (current, pageSize) => this.changePage(current, pageSize),
            },
            formItems: Obj.formItems,
            expand: false,
            form: this.$form.createForm(this, {name: 'advanced_search'}),
            equipmentStatusParams: {
                deviceCode: '',
                deviceStatus: '',
                equipmentCode: '',
                equipmentStatus: ''
            },
            jsonFields: {},
            jsonData: [],
        };
    },
    components: {uploadComm, EquipmentDetailDrawer},
    computed: {
        count() {
            return this.expand ? this.formItems.length : 3;
        },
    },
    mounted() {
        this.queryStatus()
        this.queryEquipment()
        this.queryAllEquipmentData()
    },
    methods: {
        showKsReport() {
            const r = this.$router.resolve({
                path: '/management/base/ksEquipmentReport'
            })
            window.open(r.href)
        },
        queryAllEquipmentData() {
            let param = {
                page: 1,
                limit: 1500,
                manufacturerName: '英特信'
            }
            this.jsonFields = {
                '所属区域': 'areaName',
                '设备编号': 'equipmentCode',
                '设备名称': 'name',
                '采集点编号': 'pointCode'
            }
            queryEquipmentList({...param}).then(res => {
                this.filterJsonData(res.result.content, 'jsonData')
            })
        },
        filterJsonData(data) {
            this.jsonData = []
            data.forEach((item, index) => {
                this.jsonData.push({
                    areaName: item.collectionPoint.address.areaName,
                    equipmentCode: item.equipmentCode,
                    name: item.information.name,
                    pointCode: item.collectionPoint.pointCode
                })
            })
        },
        refresh(record) {
            refreshEquipmentInfoApi(record.equipmentCode).then(res => {
                this.$message.success('操作成功')
                this.queryEquipment()
            })
        },
        toggle() {
            this.expand = !this.expand;
        },
        selectEquipmentStatus(e) {
            this.equipmentStatusParams.deviceStatus = e
        },
        confirmAddWhiteList(record) {
            updateEquipmentWhiteApi({deviceCode: record.equipmentCode}).then(res => {
                this.$message.success('操作成功')
                this.queryEquipment()
            }).catch(() => {
                this.$message.error('操作失败')
            })
        },
        changeEquipmentStatus() {
            updateEquipmentStatusApi({...this.equipmentStatusParams}).then(res => {
                this.$message.success('修改设备状态成功')
                this.visibleEquipmentStatus = false
                this.queryEquipment()
            })
        },
        hideModal() {
            this.visibleEquipmentStatus = false
        },
        showEditStatus(current) {
            this.visibleEquipmentStatus = true
            this.equipmentStatusParams.deviceCode = current.equipmentCode
            // this.equipmentStatusParams.deviceStatus = current.equipmentStatus
        },
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryEquipment()
        },
        deleteEquipment() {
            let ids = []
            this.selectedRowKeys.forEach(item => {
                ids.push(this.tableData[item].id)
            })
            if (ids.length > 0) {
                deleteEquipmentApi(ids).then(res => {
                    this.$message.success('删除成功')
                    this.pagination.current = 1
                    this.pagination.total = 0
                    this.queryEquipment()
                    this.selectedRowKeys = []
                })
                                       .catch(() => {
                                           this.$message.error('删除失败')
                                       })
            } else {
                this.$message.warning('请先选择要删除的项')
            }
        },
        changDate(moment, date) {
            this.params.deliveryStartTime = moment[0]
            this.params.deliveryEndTime = moment[1]
        },
        change(e, value) {
            this.params[value] = e.target.value
        },
        viewDetail(record) {
            this.tableDataBak.forEach(item => {
                if (item.id === record.id) {
                    item.pointName = item.collectionPoint.pointName
                    // this.$router.push({path: '/equipment/equipmentDetail', query: {record: item}})
                    this.$refs.equipmentDetailDrawer.showDrawer(item)
                }
            })
        },
        onSearch() {
            this.pagination.current = 1
            this.pagination.total = 0
            this.queryEquipment()
        },
        downloadEquipment() {
            exportEquipmentApi().then(res => {
                var blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                var downloadElement = document.createElement('a')
                var href = window.URL.createObjectURL(blob) // 创建下载的链接
                downloadElement.href = href
                downloadElement.download = '设备信息表.xlsx'
                document.body.appendChild(downloadElement)
                downloadElement.click() // 点击下载
                document.body.removeChild(downloadElement) // 下载完成移除元素
                window.URL.revokeObjectURL(href)
            }).catch((err) => {
                this.$message.error(err)
            })
        },
        handleChangeSelect(event) {
            this.params.equipmentStatus = event
            this.onSearch()
        },
        uploadEquipment() {
            this.$refs.uploadComm.showModal()
        },
        queryEquipment() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryEquipmentList({...this.params}).then(res => {
                this.loading = false
                this.tableDataBak = res.result.content
                this.filterTableData(res.result.content)
                this.pagination.current = res.result.pageNo + 1
                this.pagination.total = res.result.totalElements
            })
        },
        filterTableData(data) {
            this.tableData = []
            let tmpObj = {}
            let tmpArr = []
            data.forEach((item, index) => {
                tmpObj = {...item.information, ...item, createdTime: moment(item.createdTime).format('YYYY-MM-DD')}
                tmpArr.push(tmpObj)
                tmpObj = {}
            })
            this.tableData = tmpArr
        },
        queryStatus() {
            queryEquipmentStatus().then(res => {
                this.statusList = res.result
                this.statusList.forEach(item => {
                    item.text = item.name
                    item.value = item.code
                })
                this.columns.forEach(item => {
                    if (item.key === 'equipmentStatus') {
                        item.filters = this.statusList
                    }
                })
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },

        handleReset() {
            this.params = {
                equipmentCode: '',
                equipmentName: '',
                equipmentStatus: '',
                deliveryCode: '',
                manufacturerName: '',
                deliveryStartName: '',
                deliveryEndName: ''
            }
            this.queryEquipment()
        },

        toggle() {
            this.expand = !this.expand;
        },
    },
};
</script>
<style>
.index {
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}

.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

#components-form-demo-advanced-search .ant-form {
    max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
</style>