<template>
    <a-modal
            :title="title"
            :visible="visible"
            @cancel="handleCancel"
            @ok="handleOk"
    >
        <a-upload
                :action="action"
                name="file"
                @change="handleChange"
        >
            <a-button>
                <a-icon type="upload"/>
                选择文件
            </a-button>
        </a-upload>
    </a-modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '上传'
        },
        action: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        handleChange(info) {
            if (info.file.status === 'done') {
                this.$message.success('上传成功');
            } else if (info.file.status === 'error') {
                this.$message.error('上传失败');
            }
            this.$emit('querydata')
        },
        handleOk() {
            this.visible = false
        },
        handleCancel() {
            this.visible = false
        },
        showModal() {
            this.visible = true
        },
        hiddenModal() {
            this.visible = false
        },
    }
}
</script>