export default {
    formItems: [
        {
            label: '设备编号',
            value: 'equipmentCode',
            type: 'input'
        },
        {
            label: '设备名称',
            value: 'equipmentName',
            type: 'input'
        },
        {
            label: '设备状态',
            value: 'equipmentStatus',
            type: 'select'
        },
        {
            label: '出厂编号',
            value: 'deliveryCode',
            type: 'input'
        },
        {
            label: '厂商名称',
            value: 'manufacturerName',
            type: 'input'
        },
        {
            label: '出厂时间',
            value: ['deliveryStartTime', 'deliveryEndTime'],
            type: 'date'
        },
        {
            label: '归集点名称',
            value: 'centerName',
            type: 'input'
        }
    ],
    columns: [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '设备名称',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
        },
        {
            title: '设备编号',
            dataIndex: 'equipmentCode',
            key: 'equipmentCode',
            ellipsis: true,
            scopedSlots: { customRender: 'equipmentCode'}
        },
        {
            title: '设备状态',
            dataIndex: 'equipmentStatus',
            key: 'equipmentStatus',
            scopedSlots: { customRender: 'equipmentStatus'},
        },
        {
            title: '出厂编号',
            dataIndex: 'deliveryCode',
            key: 'deliveryCode',
            ellipsis: true,
            scopedSlots: { customRender: 'deliveryCode'},
        },
        {
            title: '厂商名称',
            dataIndex: 'manufacturerName',
            key: 'manufacturerName',
            scopedSlots: { customRender: 'manufacturerName'},

        },
        {
            title: '上传时间',
            dataIndex: 'createdTime',
            key: 'createdTime'
        },
        {
            title: '是/否加入白名单',
            dataIndex: 'addWhiteList',
            key: 'addWhiteList',
            scopedSlots: { customRender: 'addWhiteList'}
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 300,
            scopedSlots: { customRender: 'action' },
        }
    ],
    detailItems: [
        {
            label: '设备编号',
            key: 'equipmentCode',
            value: 1,
            disabled: true
        },
        {
            label: '设备名称',
            key: 'name',
            value: 1,
            disabled: false

        },
        {
            label: '设备类型',
            key: 'equipmentType',
            value: 1,
            disabled: true

        },
        {
            label: '出厂编号',
            key: 'deliveryCode',
            value: 1,
            disabled: true
        },
        {
            label: '出厂日期',
            key: 'deliveryTime',
            value: 1,
            disabled: true


        },
        {
            label: '厂商编号',
            key: 'manufacturerCode',
            value: 1,
            disabled: true

        },
        {
            label: '厂商名称',
            key: 'manufacturerName',
            value: 1,
            disabled: true
        },
        {
            label: '设备状态',
            key: 'equipmentStatus',
            value: 1,
            disabled: true

        },
        {
            label: '当前是否绑定',
            key: 'isBind',
            value: '否',
            type: 'input',
            disabled: true
        },
        {
            label: '已绑定采集点',
            key: 'pointName',
            value: '御景花园',
            disabled: true
        }
    ],
    residentialItems:[
        {
            title: '归集点名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '采集点数量',
            dataIndex: 'collectionPoint',
            key: 'collectionPoint'
        },
        {
            title: '归集点类别',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '所属城市',
            dataIndex: 'cityName',
            key: 'cityName'
        },
        {
            title: '所属街道',
            dataIndex: 'streetName',
            key: 'streetName'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
        }
    ]
}